<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Memberships</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <card shadow type="secondary">
                  <form>
                    <div class="pl-lg-4">
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Membership Code"
                            input-classes="form-control-alternative"
                            :value="model.membership_code"
                            @input="
                              (event) =>
                                (model.membership_code = event.target.value)
                            "
                          />
                          <!-- <div
                            class="error mb-2"
                            v-if="v$.model.membership_code.$error"
                          >
                            Membership Code is required.
                          </div> -->
                        </div>
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Membership Name"
                            input-classes="form-control-alternative"
                            :value="model.membership_name"
                            @input="
                              (event) =>
                                (model.membership_name = event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.membership_name.$error"
                          >
                            Membership Name is required.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Membership Charge"
                            input-classes="form-control-alternative"
                            :value="model.membership_charge"
                            @input="
                              (event) =>
                                (model.membership_charge = event.target.value)
                            "
                          />
                          <div
                            class="error mb-2"
                            v-if="v$.model.membership_charge.$error"
                          >
                            Invalid Membership Charge.
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <base-input label="Duration">
                            <el-select
                              style="width: 100%"
                              placeholder="Duration"
                              v-model="model.duration"
                              :value="model.duration"
                              filterable
                              @change="onChange(model.duration)"
                            >
                              <el-option
                                v-for="option in selects.durationexec"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <div
                            class="error mb-2"
                            v-if="v$.model.duration.$error"
                          >
                            Duration is required.
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <base-input label="Duration Exec" v-if="ifWeek">
                            <el-select
                              style="width: 100%"
                              placeholder="Duration"
                              v-model="model.duration_exec"
                              :value="model.duration_exec"
                              filterable
                            >
                              <el-option
                                v-for="option in selects.weekly"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <base-input label="Duration Exec" v-if="ifMonth">
                            <el-select
                              style="width: 100%"
                              placeholder="Duration"
                              v-model="model.duration_exec"
                              :value="model.duration_exec"
                              filterable
                            >
                              <el-option
                                v-for="option in selects.monthly"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <base-input label="Duration Exec" v-if="iffortnight">
                            <el-select
                              style="width: 100%"
                              placeholder="Duration"
                              v-model="model.duration_exec"
                              :value="model.duration_exec"
                              filterable
                            >
                              <el-option
                                v-for="option in selects.fortnightly"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <base-input label="Duration Exec" v-if="ifyear">
                            <flat-picker
                              @on-open="focus"
                              @on-close="blur"
                              :config="{ altFormat: 'd/m/Y', altInput: true }"
                              class="form-control datepicker"
                              v-model="model.date"
                            >
                            </flat-picker>
                          </base-input>
                        </div>
                      </div>
                      <div class="row1">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            v-model="model.is_active"
                            false-value="2"
                            true-value="1"
                          />
                          <label class="custom-control-label" for="customCheck1"
                            >Active?</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="id.data == 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="create()"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="id.data != 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="Update()"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div v-if="isLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Created
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                  <div v-if="isUpdateLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Updated
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
const memberShipRepository = RepositoryFactory.get("membership");

import { ElSelect, ElOption } from "element-plus";
export default {
  components: {
    flatPicker,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  name: "Membership",
  data() {
    return {
      v$: useVuelidate(),
      id: {},
      isLoading: false,
      ifWeek: false,
      ifMonth: false,
      iffortnight: false,
      ifyear: false,
      isUpdateLoading: false,

      selects: {
        simple: "",
        durationexec: [
          { value: "Daily", label: "Daily" },
          { value: "Weekly", label: "Weekly" },
          { value: "Fortnightly", label: "Fortnightly" },
          { value: "Monthly", label: "Monthly" },
          { value: "Yearly", label: "Yearly" },
          { value: "Manual", label: "Manual" },
        ],
        weekly: [
          { value: "Sunday", label: "Sunday" },
          { value: "Monday", label: "Monday" },
          { value: "Tuesday", label: "Tuesday" },
          { value: "Wednesday", label: "Wednesday" },
          { value: "Thursday", label: "Thursday" },
          { value: "Friday", label: "Friday" },
          { value: "Saturday", label: "Saturday" },
        ],
        fortnightly: [
          { value: "1st Week Sunday", label: "1st Week Sunday" },
          { value: "1st Week Monday", label: "1st Week Monday" },
          { value: "1st Week Tuesday", label: "1st Week Tuesday" },
          { value: "1st Week Wednesday", label: "1st Week Wednesday" },
          { value: "1st Week Thursday", label: "1st Week Thursday" },
          { value: "1st Week Friday", label: "1st Week Friday" },
          { value: "1st Week Saturday", label: "1st Week Saturday" },
          { value: "2nd Week Sunday", label: "1st Week Sunday" },
          { value: "1st Week Monday", label: "2nd Week Monday" },
          { value: "2nd Week Tuesday", label: "2nd Week Tuesday" },
          { value: "2nd Week Wednesday", label: "2nd Week Wednesday" },
          { value: "2nd Week Thursday", label: "2nd Week Thursday" },
          { value: "2nd Week Friday", label: "2nd Week Friday" },
          { value: "2nd Week Saturday", label: "2nd Week Saturday" },
        ],

        monthly: [
          { value: "1st", label: "1st" },
          { value: "2nd", label: "2nd" },
          { value: "3rd", label: "3rd" },
          { value: "4th", label: "4th" },
          { value: "5th", label: "5th" },
          { value: "6th", label: "6th" },
          { value: "7th", label: "7th" },
          { value: "8th", label: "8th" },
          { value: "9th", label: "9th" },
          { value: "10th", label: "10th" },
          { value: "11th", label: "11th" },
          { value: "12th", label: "12th" },
          { value: "13th", label: "13th" },
          { value: "14th", label: "14th" },
          { value: "15th", label: "15th" },
          { value: "16th", label: "16th" },
          { value: "17th", label: "17th" },
          { value: "18th", label: "18th" },
          { value: "19th", label: "19th" },
          { value: "20th", label: "20th" },
          { value: "21th", label: "21th" },
          { value: "22th", label: "22th" },
          { value: "23th", label: "23th" },
          { value: "24th", label: "24th" },
          { value: "25th", label: "25th" },
          { value: "26th", label: "26th" },
          { value: "27th", label: "27th" },
          { value: "28th", label: "28th" },
          { value: "29th", label: "29th" },
        ],
      },
      model: {
        membership_name: "",
        membership_code: "",
        membership_charge: "",
        duration: "",
        is_active: "",
      },
    };
  },
  methods: {
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }

      // this.model.Subscriptions = JSON.stringify(this.model.Subscriptions);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await memberShipRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/manageCharges/view_Membership");
        }, 1000);
      }
    },
    async Update() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }

      delete this.model.edit;
      // this.model.Subscriptions = JSON.stringify(this.model.Subscriptions);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await memberShipRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("/manageCharges/view_Membership");
        }, 1000);
      }
    },

    onChange(event) {
      if (event === "Weekly") {
        this.ifWeek = true;
        this.ifMonth = false;
        this.iffortnight = false;
        this.ifyear = false;
      } else if (event === "Monthly") {
        this.ifMonth = true;
        this.ifWeek = false;
        this.iffortnight = false;
        this.ifyear = false;
      } else if (event === "Fortnightly") {
        this.iffortnight = true;
        this.ifWeek = false;
        this.ifMonth = false;
        this.ifyear = false;
      } else if (event === "Yearly") {
        this.ifyear = true;
        this.ifWeek = false;
        this.ifMonth = false;
        this.iffortnight = false;
      } else {
        this.ifWeek = false;
        this.ifMonth = false;
        this.iffortnight = false;
        this.ifyear = false;
      }
    },
  },

  validations() {
    return {
      model: {
        // membership_code: { required },
        membership_name: { required },
        membership_charge: { required, numeric },
        duration: { required },
      },
    };
  },

  mounted() {
    this.id = this.$route.params;
    this.model = {};
    memberShipRepository.getMembershipByid(this.id.data).then((data) => {
      if (data.data.length != 0) {
        this.model = data.data[0];
      }

      if (this.model.duration === "Weekly") {
        this.ifWeek = true;
        this.ifMonth = false;
        this.iffortnight = false;
        this.ifyear = false;
      } else if (this.model.duration === "Monthly") {
        this.ifMonth = true;
        this.ifWeek = false;
        this.iffortnight = false;
        this.ifyear = false;
      } else if (this.model.duration === "Fortnightly") {
        this.iffortnight = true;
        this.ifWeek = false;
        this.ifMonth = false;
        this.ifyear = false;
      } else if (this.model.duration === "Yearly") {
        this.ifyear = true;
        this.ifWeek = false;
        this.ifMonth = false;
        this.iffortnight = false;
      }
    });
  },
};
</script>

<style></style>
